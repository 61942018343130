<template>
  <div
    class="position-fixed w-100 top-0 left-0"
    style="z-index: 1001;"
  >
    <div
      v-show="isActiveCall"
      class="w-100 vh-100 bg-dark bg-opacity-75"
    >
      <div class="call-view position-relative top-md-50 mx-auto bg-black overflow-md-hidden">
        <div
          class="position-absolute d-flex justify-content-between align-items-center"
          style="top: 15px; left: 15px; right: 15px;"
        >
          <div style="position: absolute; top: 100%; z-index: 1; background: #FFFFFF">
            {{ bitrate }}
          </div>
          <div
            class="text-white overflow-hidden"
            style="text-overflow: ellipsis; white-space: nowrap;"
          >
            {{ getActiveCall?.name }}
          </div>
          <div
            class="text-primary text-center bg-white rounded-20 px-15 py-10"
            style="width: 75px; line-height: 1;"
          >
            {{ getTime }}
          </div>
        </div>
        <div
          v-show="!isConnected || isRemoteVideoMuted"
          class="position-absolute top-50 text-center"
          style="left: 50%; transform: translateX(-50%) translateY(-50%)"
        >
          <div
            v-show="isCalling || isConnection"
            class="text-white fw-bold"
          >
            {{ isConnection ? 'Соединяем...' : 'ЗВОНИМ...' }}
          </div>
          <img
            class="mt-25 mb-10 rounded-20"
            height="80"
            width="80"
            style="object-fit: contain"
            :src="getActiveCall?.logo??'https://moihottur.ru/assets/images/icons/staff_75.png'"
            :alt="getActiveCall?.name"
          >
          <div class="text-white fw-bold">
            {{ getActiveCall?.name }}
          </div>
        </div>
        <div class="h-100 w-100">
          <video
            v-show="!isRemoteVideoMuted"
            ref="remoteVideo"
            class="h-100 w-100"
            playsinline="true"
            autoplay
          />
          <audio
            ref="remoteAudio"
            autoplay
          />
        </div>
        <video
          class="call-view-local position-absolute rounded-20"
          :class="isVideoMuted ? 'd-none' : null"
          autoplay
          playsinline="true"
          :srcObject.prop="localStream"
        />
        <div class="position-absolute bottom-0 d-flex justify-content-center w-100 gap-15 mb-15">
          <AppButton
            :title="(isAudioMuted ? 'Включить' : 'Выключить') + ' микрофон'"
            :variant="BtnVariant.White"
            is-icon
            :style="isAudioMuted ? 'text-decoration: line-through' : null"
            @click="toggleAudio"
          >
            <svg class="w-100  h-100">
              <use :xlink:href="'/public/icons/callSprite.svg#mic' + (isAudioMuted ? '-off' : '')" />
            </svg>
          </AppButton>
          <AppButton
            :title="(isAudioMuted ? 'Включить' : 'Выключить') + ' камеру'"
            :variant="BtnVariant.White"
            is-icon
            :style="isVideoMuted ? 'text-decoration: line-through' : null"
            @click="toggleVideo"
          >
            <svg class="h-100 w-100">
              <use :xlink:href="'/public/icons/callSprite.svg#videocam' + (isVideoMuted ? '-off' : '')" />
            </svg>
          </AppButton>
          <AppButton
            class="btn-book"
            variant
            is-icon
            title="Завершить звонок"
            @click="hangUp()"
          >
            <svg class="h-100 w-100">
              <use xlink:href="/public/icons/callSprite.svg#call-end" />
            </svg>
          </AppButton>
        </div>
      </div>
    </div>
    <div
      class="position-fixed container-xxl d-flex flex-wrap justify-content-center align-items-center gap-25"
      style="z-index: 1; top: 50%; left: 50%; transform: translateX(-50%) translateY(-50%)"
    >
      <div
        v-for="call in getIncomingCalls"
        class="rounded-20 bg-primary bg-brand-gradient px-34 py-20"
        style="max-width: 320px"
      >
        <div
          class="text-white text-center fw-bold"
          style="font-size: 24px;"
        >
          ВАМ ЗВОНОК...
        </div>
        <div class="text-center mt-24 mb-10">
          <img
            class="rounded-20"
            style="height: 120px; width: 120px;"
            :src="call?.logo??'https://moihottur.ru/assets/images/icons/staff_75.png'"
            :alt="call?.name"
          >
        </div>
        <div
          class="text-white text-center fw-bold"
          style="font-size: 24px;"
        >
          {{ call.name }}
        </div>
        <div class="d-flex justify-content-center gap-25 mt-43">
          <AppButton
            class="fw-bold"
            :variant="BtnVariant.Secondary"
            @click="accept(call.colloquyId)"
          >
            Ответить
          </AppButton>
          <AppButton
            :variant="BtnVariant.White"
            is-icon
            @click="hangUp(call.colloquyId)"
          >
            <svg class="h-100 w-100">
              <use xlink:href="/public/icons/main.svg#close" />
            </svg>
          </AppButton>
        </div>
      </div>
    </div>
    <audio
      ref="audioNotification"
      src="/public/call/drumbit.mp3"
      @ended="restartAudioNotification"
    />
    <audio
      ref="audioNotificationHangUp"
      src="/public/call/hang-up.mp3"
    />
  </div>
</template>

<script setup lang="ts">
import { Centrifuge } from "centrifuge";
import { useClient } from "~/stores/client";
import { default as adapter } from 'webrtc-adapter';
import { default as Janus } from "../assets/js/janus";
import {BtnVariant} from "~/composables/enums/BtnVariant";

const runtimeConfig = useRuntimeConfig();
const client = useClient();

//-----PROPS-----\\
const props = defineProps({
  centrifuge: { type: Centrifuge, required: true }
});

//-----TYPE-----\\
type Call = {
  colloquyId: string,
  descriptorId: string,
  name: string,
  logo: string|null,
  active?: boolean
}

//-----ENTITY-----\\
enum CallStatus {
  Calling,
  Connection,
  Connected
}

//-----VARIABLES-----\\
const centrifuge = unref(props.centrifuge);
const remoteTracks: MediaStream[] = [];
let janus: Janus|null = null;
let videoCall: PluginHandle|null = null;
let bitrateTimer: Timer|null = null;
let stopwatchInterval: Timer|null = null;
let audioNotificationTimer: Timeout|null = null;
const maxWait: number = 60000;
let waitTimerTimeout: Timer;

//-----STATE-----\\
const remoteVideo = ref();
const remoteAudio = ref();

const calledClient = useState<Colloquy|null>('calledClient', () => null);
const calls = ref<Call[]>([]);
const bitrate = ref<string|null>(null);
const stopwatch = ref<number>(0);
const localStream = ref<MediaStream|null>(null);
const isRemoteAudioMuted = ref<boolean>(false);
const isRemoteVideoMuted = ref<boolean>(false);
const isAudioMuted = ref<boolean>(false);
const isVideoMuted = ref<boolean>(false);
const callStatus = ref<CallStatus|null>(null);
const audioNotification = ref();
const audioNotificationHangUp = ref();

//-----COMPUTED-----\\
const getTime = computed((): string => {
  if (stopwatch.value === null) {
    return '--:--';
  }

  const minutes = Math.floor(stopwatch.value / 60);
  const seconds = stopwatch.value - minutes * 60;
  return (minutes === 0 ? '00' : (minutes < 10 ? '0' + minutes : minutes)) + ':' +
      (seconds === 0 ? '00' : (seconds < 10 ? '0' + seconds : seconds));
});
const getIncomingCalls = computed(() => {
  return calls.value.filter(val => !val.active);
});
const isActiveCall = computed((): boolean => callStatus.value !== null);
const isCalling = computed((): boolean => callStatus.value === CallStatus.Calling);
const isConnection = computed((): boolean => callStatus.value === CallStatus.Connection);
const isConnected = computed((): boolean => callStatus.value === CallStatus.Connected);
const getActiveCall = computed((): Call|null => {
  return isActiveCall.value ? calls.value.find(val => val.active) : null;
});
const audioNotificationActive = computed((): boolean => isCalling.value || isConnection.value);

//-----WATCH-----\\
watch(() => calledClient.value, (newState: Colloquy|null) => {
  if (newState === null) {
    return;
  }
  initCall();
  addCall({
    colloquyId: newState.id,
    descriptorId: newState.client.id,
    name: newState.client.name + ' ' + newState.client.surname,
    logo: newState.client.logo,
  }, true);
});
watch(() => getIncomingCalls.value.length > 0 || audioNotificationActive.value, (status) => {
  if (status) {
    playAudioNotification();
  } else {
    stopAudioNotification();
  }
});

//-----METHODS-----\\
function addCall(data: Call, active: boolean = false) {
  calls.value.push({
    active,
    ...data
  });

  if (active) {
    callStatus.value = CallStatus.Calling;
    // playAudioNotification();
  }
}
function initCall() {
  getApiToken();
}
async function getApiToken(colloquyId: string|undefined = undefined) {
  await mainFetch('calls/auth', {
    method: 'POST',
    body: { colloquyId: colloquyId??calledClient.value!.id }
  }).then(response => {
    response = response.data.value??response.error.value?.data;
    if (response.error.code !== 200 || response.token === null) {
      return;
    }

    janusInit(response.token);
  });
}
function janusInit(token: string) {
  Janus.init({
    dependencies: Janus.useDefaultDependencies({ adapter }),
    debug: true,
    callback: () => {
      if (!Janus.isWebrtcSupported()) {
        alert('No WebRTC support...');
        return;
      }

      janus = new Janus({
        server: (runtimeConfig.public.protocol === 'https' ? 'wss' : 'ws') + '://call.' + runtimeConfig.public.domain,
        token: token,
        iceServers: [
          {
            urls: "stun:stun.l.google.com:19302"
          }
        ],
        success: () => {
          janus!.attach({
            plugin: 'janus.plugin.videocall',
            opaqueId: getActiveCall.value.colloquyId,
            success: janusSuccess,
            onlocaltrack: janusOnLocalTrack,
            onremotetrack: janusOnRemoteTrack,
            mediaState: mediaState,
            onmessage: janusOnMessage,
            ondata: janusOnData,
            oncleanup: janusOnCleanup,
            error: janusError
          });
        },
        error: janusError
      });
    }
  });
}
//---janus handler---\\
function janusSuccess(pluginHandle: PluginHandle) {
  videoCall = pluginHandle;
  videoCall.send({
    message: {
      request: 'register',
      // username: getDescriptorId()
      username: client.getClientId
    }
  });

  if (calledClient.value === null) {
    janusInitCall();
  } else {
    sendInitCall();
  }
}
function janusOnLocalTrack(track: MediaStreamTrack, on: boolean) {
  if (!isVideoMuted.value && on) {
    localStream.value = new MediaStream([track]);
  }
}
function janusOnRemoteTrack(track: MediaStreamTrack, mid: string, on: boolean) {
  Janus.debug("Remote track (mid=" + mid + ") " + (on ? "added" : "removed") + ":", track);
  if (!on) {
    const stream = remoteTracks[mid];
    if (stream) {
      /*try {
        const tracks = stream.getTracks();
        for (const i in tracks) {
          const mst = tracks[i];
          if (mst) {
            mst.stop();
          }
        }
      } catch (e) {
        console.log(e);
      }*/
      delete remoteTracks[mid];
    }

    return;
  }

  const stream = new MediaStream([ track ]);
  remoteTracks[mid] = stream;

  if (track.kind === 'audio') {
    Janus.attachMediaStream(remoteAudio.value, stream);
    isRemoteAudioMuted.value = false;
  } else {
    Janus.attachMediaStream(remoteVideo.value, stream);
    isRemoteVideoMuted.value = false;
  }

  if (bitrateTimer === null) {
    bitrateTimer = setInterval(function() {
      bitrate.value = videoCall!.getBitrate();
    }, 1000);
  }
}
function janusOnMessage(message: Message, jsep?: JSEP) {
  Janus.debug(' ::: Got a message :::', message);
  if (message.result === undefined) {
    Janus.log('result undefined');
    hangUp();
    return;
  }

  const event: string = message.result.event;
  if (event === 'incomingcall') {//Входящий звонок
    acceptInitCall(message.result.username, jsep!);
  } else if (event === 'accepted') {//Событие принятия звонка, сробатывает для всех сторон
    videoCall!.handleRemoteJsep({ jsep: jsep });
    // isSettingCall.value = false;
    callStatus.value = CallStatus.Connected;
    stopwatchInterval = setInterval(() => {
      stopwatch.value++;
    }, 1000);
  } else if (event === 'hangup') {//Событие завершение звонка, получают все стороны
    hangUp();
    // clearCall();
  } else if (event === 'update') {
    if (jsep.type === 'answer') {
      videoCall!.handleRemoteJsep({ jsep: jsep });
    } else {
      videoCall!.createAnswer({
        jsep: jsep,
        media: { data: true },
        success: function(jsep) {
          Janus.debug("Got SDP!", jsep);
          videoCall!.send({ message: { request: "set" }, jsep: jsep });
        },
        error: function(error) {
          Janus.error("WebRTC error:", error);
        }
      });
    }
  }
}
function janusOnData(data) {
  Janus.debug("We got data from the DataChannel!", data);
  data = JSON.parse(data);
  if (data.command === undefined) {
    return;
  }

  if (data.command === 'changeMediaSettings') {
    isRemoteAudioMuted.value = data.data.isAudioMuted;
    isRemoteVideoMuted.value = data.data.isVideoMuted;
  }
}
function janusOnCleanup() {
  Janus.log('::: Got a cleanup notification :::');
}
function janusError(err: string) {
  hangUp();
  Janus.error(err);
}
//---test janus---\\
function iceState(state) {
  Janus.log("ICE state changed to " + state);
}
function mediaState(medium, on, mid) {
  Janus.log("Janus " + (on ? "started" : "stopped") + " receiving our " + medium + " (mid=" + mid + ")");
}
function slowLink(uplink, lost, mid) {
  Janus.warn("Janus reports problems " + (uplink ? "sending" : "receiving") +
      " packets on mid " + mid + " (" + lost + " lost packets)");
}
//---/janus handler---\\
async function janusInitCall() {
  await videoCall.createOffer({
    // tracks: [
    //   { type: 'audio', capture: true, recv: true },
    //   { type: 'video', capture: true, recv: true },
    //   { type: 'data' }
    // ],
    media: { data: true },
    success: function(jsep) {
      Janus.debug("Got SDP!", jsep);
      videoCall.send({
        message: {
          request: 'call',
          username: getActiveCall.value.descriptorId
        },
        jsep
      });

      callStatus.value = CallStatus.Connection;
    },
    iceState: iceState,
    mediaState: mediaState,
    slowLink: slowLink,
    error: function(error) {
      Janus.error("WebRTC error...", error);
    }
  });
}
async function sendInitCall() {
  await mainFetch('calls', {
    method: 'POST',
    body: {
      colloquyId: calledClient.value!.id,
      // descriptorId: getDescriptorId()
      descriptorId: client.getClientId
    }
  }).then(({ status }) => {
    if (status.value !== "success") {
      return;
    }

    waitTimerTimeout = setTimeout(() => {
      if (callStatus.value === CallStatus.Calling) {
        hangUp();
      }
    }, maxWait);
  }).catch(() => {
    hangUp();
  });
}
function acceptInitCall(descriptorId: string, jsep: JSEP) {
  descriptorId = descriptorId.split(':')[0];
  if (descriptorId !== calledClient.value!.client.id) {
    return;
  }

  videoCall!.createAnswer({
    jsep,
    // We want bidirectional audio and video, if offered,
    // plus data channels too if they were negotiated
    /*tracks: [
      { type: 'audio', capture: true, recv: true },
      { type: 'video', capture: true, recv: true },
      { type: 'data' },
    ],*/
    media: { data: true },	// ... let's negotiate data channels as well
    success: function(jsep: JSEP) {
      Janus.debug("Got SDP!", jsep);
      videoCall!.send({
        message: { request: 'accept' },
        success: () => {
          clearTimeout(waitTimerTimeout);
        },
        jsep
      });
      // isActiveCall.value = true;
      // stopAudioNotification();
    },
    iceState: iceState,
    mediaState: mediaState,
    slowLink: slowLink,
    error: function(error) {
      Janus.error("WebRTC error:", error);
    }
  });
}
function accept(id: string) {
  if (isActiveCall.value) {
    hangUp();
  }
  const index = calls.value.findIndex(val => val.colloquyId === id);
  callStatus.value = CallStatus.Connection;
  calls.value[index].active = true;

  getApiToken(id);
}
function hangUp(id?: string) {
  const index = calls.value.findIndex(val => id === undefined ? val.active : val.colloquyId === id);
  if (index === -1) {//Если звонок не найден
    return;
  }

  playAudioNotificationHangUp();

  if (!calls.value[index].active) {//Если это входящий (еще не принятый) звонок
    calls.value.splice(index, 1);
    callStatus.value = null;
    return;
  }

  remoteVideo.value.srcObject = undefined;
  remoteAudio.value.srcObject = undefined;

  if (videoCall !== null) {
    videoCall.send({ message: { request: 'hangup' } });
    videoCall!.hangup();
    videoCall!.detach();
  }

  if (!calls.value[index].active || isCalling) {//Если звонок еще не начат, отправляем уведомление в центрифугу
    mainFetch('calls', {
      method: 'DELETE',
      body: {
        colloquyId: calls.value[index].colloquyId,
        descriptorId: client.getClientId
      }
    });
  }

  calls.value.splice(index, 1);
  callStatus.value = null;

  clearInterval(waitTimerTimeout);

  clearCall();
}
function clearCall() {
  calledClient.value = null;

  localStream.value = null;

  for (const mid in remoteTracks) {
    const stream = remoteTracks[mid];
    if (stream) {
      try {
        const tracks = stream.getTracks();
        for (const i in tracks) {
          const mst = tracks[i];
          if (mst) {
            mst.stop();
          }
        }
      } catch (e) {
        console.log(e);
      }
      delete remoteTracks[mid];
    }
  }

  clearInterval(bitrateTimer);
  bitrateTimer = null;

  clearInterval(stopwatchInterval);
  stopwatch.value = 0;

  videoCall = null;

  if (janus !== null) {
    janus.destroy();
  }
}
//---media settings---\\
function changeMediaSettings() {
  videoCall.data({
    text: JSON.stringify({
      command: 'changeMediaSettings',
      data: {
        isVideoMuted: isVideoMuted.value,
        isAudioMuted: isAudioMuted.value
      }
    })
  });
}
function toggleAudio() {
  isAudioMuted.value = !isAudioMuted.value;
  changeMediaSettings();
  if (isAudioMuted.value) {
    videoCall.muteAudio();
  } else {
    videoCall.unmuteAudio();
  }
  videoCall.send({ message: { request: "set", audio: !isAudioMuted.value }});
}
function toggleVideo() {
  isVideoMuted.value = !isVideoMuted.value;
  changeMediaSettings();
  if (isVideoMuted.value) {
    videoCall.muteVideo();
  } else {
    videoCall.unmuteVideo();
  }
  videoCall.send({ message: { request: "set", video: !isVideoMuted.value }});
}
//---audio notification---\\
function playAudioNotification() {
  audioNotification.value.currentTime = 0;
  audioNotification.value.play();
}
function restartAudioNotification() {
  if (getIncomingCalls.value.length === 0 && !audioNotificationActive.value) {
    return;
  }
  audioNotificationTimer = setTimeout(playAudioNotification, 500);
}
function stopAudioNotification() {
  if (audioNotificationTimer !== null) {
    clearTimeout(audioNotificationTimer);
    audioNotificationTimer = null;
  }
  audioNotification.value.pause();
}
function playAudioNotificationHangUp() {
  audioNotificationHangUp.value.currentTime = 0;
  audioNotificationHangUp.value.play();
}

onMounted(() => {
  mainFetch('calls', {}, true).then(resp => {
    resp = resp.data.value??resp.error.value?.data;
    if (resp.call === null) {
      return;
    }

    addCall({
      colloquyId: resp.call.colloquyId,
      descriptorId: resp.call.descriptorId,
      name: resp.call.name,
      logo: resp.call.logo,
    });
  });
  centrifuge.on('publication', (ctx) => {
    if (ctx.channel !== 'calls:#' + client.getClientId) {
      return;
    }

    if (ctx.data.value.event === 'initCall') {
      addCall({
        colloquyId: ctx.data.value.caller.colloquyId,
        descriptorId: ctx.data.value.caller.descriptorId,
        name: ctx.data.value.caller.name,
        logo: ctx.data.value.caller.logo,
      });
    } else if (ctx.data.value.event === 'hangup') {
      hangUp(ctx.data.value.caller.colloquyId);
    }
  });
});
onUnmounted(() => {
  hangUp();
});
</script>

<style scoped lang="scss">
.call {
  &-view {
    height: 100%;
    width: 100%;

    &-local {
      max-height: 140px;
      min-height: 90px;
      max-width: 140px;
      min-width: 90px;
      top: 66px;
      right: 15px;
      transform: scaleX(-1);
    }
  }
}

@media (min-width: 768px) {
  .call {
    &-view {
      transform: translateY(-50%);
      max-height: 100%;
      width: min(1288px, 90vw);
      height: max(500px, min(693px, 90vh));
      border-radius: 20px;

      &-local {
        max-height: 160px;
        min-height: unset;
        min-width: unset;
        max-width: unset;
        aspect-ratio: unset;
        top: unset;
        bottom: 85px;
        right: 15px;
      }

      &-hangup {
        padding: 0 14px;
        width: unset;
        border-radius: 20px !important;
      }
    }
  }
}
</style>
